import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const exhibitorService = createApi({
  reducerPath: "exhibitors",
  tagTypes: "exhibitors",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      getExhibitors: builder.query({
        query: (id) => {
          const url = id ? '/api/exhibitors/' + id : '/api/exhibitors';
          return {
            url: url,
            method: "GET",
          };
        },
        providesTags: ["exhibitors"],
      }),


      addExhibitor: builder.mutation({
        query: (data) => {
          return {
            url: "/api/exhibitors",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["exhibitors"],
      }),


      editExhibitor: builder.mutation({
        query: (data) => {
          return {
            url: "/api/exhibitors/" + data.id+"?_method=PUT",
            method: "POST",
            body: data.data,
          };
        },
        invalidatesTags: ["exhibitors"],
      }),
      addExhibitor: builder.mutation({
        query: (data) => {
          return {
            url: "/api/exhibitors",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["exhibitors"],
      }),


      deleteExhibitor: builder.mutation({
        query: (id) => {
          return {
            url: "/api/exhibitors/" + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["organizers"],
      }),
    };
  },
});
export const { useAddExhibitorMutation, useEditExhibitorMutation, useGetExhibitorsQuery, useDeleteExhibitorMutation } = exhibitorService;
export default exhibitorService;