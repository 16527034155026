import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const userService = createApi({
  reducerPath: "user",
  tagTypes: "users",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {


    
      getUsers: builder.query({
        query: () => {
          return {
            url: "/api/users",
            method: "GET",
          };
        },
        providesTags: ["users"],
      }),
      addUser: builder.mutation({
        query: (newUser) => {
          return {
            url: "/api/auth/register",
            method: "POST",
            body: newUser,
          };
        },
        invalidatesTags: ["users"],
      }),
      editUser: builder.mutation({
        query: (user) => {
          return {
            url: `api/users/update/${user.id}`,
            method: "POST",
            body: user,
          };
        },
        invalidatesTags: ["users"],
      }),

      deleteUser: builder.mutation({
        query: (id) => {
          return {
            url: `/api/users/delete/${id}`,
            method: "GET",
          };
        },
        invalidatesTags: ["users"],
      }),

    };
  },
});
export const { useGetUsersQuery,useAddUserMutation,useEditUserMutation,useDeleteUserMutation } = userService;
export default userService;
