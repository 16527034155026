import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import { Dropdown, Menu, Button, DatePicker } from "antd";
import { getDateString } from "../../utils/functions";
import Modal from "../../components/Modal";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetVisitorsQuery } from "../../store/services/visitorsService";
import TicketComponent from "./TicketComponent";
import { BASE_URL } from "../../Api/constants";
import moment from "moment";

const hearedFromOptions = {
  "sms": { bgColor: '#1352F1' },
  "social media": { bgColor: '#741DE3' },
  "website": { bgColor: '#17C198' },
  "friends": { bgColor: '#44A615' },
  "influencers": { bgColor: '#BE17C1' },
  "billboards": { bgColor: '#EAA800' },
  "radio": { bgColor: '#39CAEA' },
  "other": { bgColor: '#D12B2B' }
};

const Records = () => {
  const words = useSelector((state) => state.authReducer.words);
  const { data: visitorsList, isLoading: bookingLoading, refetch } = useGetVisitorsQuery();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const [printTicketModal, setPrintTicketModal] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState('');
  const [filteredVisitors, setFilteredVisitors] = useState([]);

  useEffect(() => {
    if (visitorsList) {
      setFilteredVisitors(visitorsList.data);
    }
  }, [visitorsList]);

  const handleDateChange = (date, dateString) => {
    // alert(date);
    if (dateString) {


      const filtered = visitorsList.data.filter(visitor =>
        getDateString(visitor.created_at) == getDateString(date)
      );


      // const filtered = visitorsList.data.filter(visitor =>
      //   moment(visitor.created_at).isSame(date, 'day')
      // );
      setFilteredVisitors(filtered);
    } else {
      setFilteredVisitors(visitorsList.data);
    }
  };

  const columns = [
    {
      title: words['id'],
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: words['Order Id'],
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: words['name'],
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: words['phone'],
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: words['email'],
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: words['type'],
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: words['Gender'],
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: words['Male members'],
      dataIndex: 'male_members',
      key: 'male_members',
    },
    {
      title: words['Female members'],
      dataIndex: 'female_members',
      key: 'female_members',
    },
    {
      title: words['Reached time'],
      dataIndex: 'reached_time_minutes',
      key: 'reached_time_minutes',
    },
    {
      title: words['Heared from'],
      dataIndex: 'heared_source',
      key: 'heared_source',
      render: (_, record) => (
        <div>
          <button
            disabled={true}
            className="button rounded-full !py-2 !px-5"
            style={{
              borderWidth: 0,
              backgroundColor: hearedFromOptions[record.heared_source?.toLowerCase()]?.bgColor
            }}
          >
            {record.heared_source}
          </button>
        </div>
      ),
    },
    {
      title: words['Scanned time'],
      dataIndex: 'card_scanned',
      key: 'card_scanned'
    },
    {
      title: words['Created at'],
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => (
        <div className="px-2 py-2 text-left text-sm text-black font-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words['action'],
      dataIndex: 'is_paid_to_store',
      key: 'is_paid_to_store',
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu onClick={({ key }) => {
              if (key === 'delete') {
                setDeletedId(record.id);
                setOpenDeleteModal(true);
              }
              if (key === 'print') {
                setSelectedVisitor(record);
                setPrintTicketModal(true);
              }
            }}>
              <>
                <Menu.Item key="print">{words["Print ticket"]}</Menu.Item>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={['click']}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const doChangeStatus = () => {
    // Your status change logic
  };

  if (printTicketModal) return (
    <TicketComponent
      name={selectedVisitor.full_name}
      qrLink={`${BASE_URL}/organizer/scan-qr-code/${selectedVisitor.id}`}
      phone={selectedVisitor.phone}
      ticketNo={selectedVisitor.order_id}
      close={() => setPrintTicketModal(false)}
    />
  );

  return (
    <Layout pageTitle={words["Visitors"]}>
      <div className="finance-page min-h-screen flex">
        {bookingLoading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                <DatePicker
                  onChange={handleDateChange}
                  style={{ marginRight: 16 }}
                />
                <Button
                  style={{ color: 'black' }}
                  onClick={() => handleDateChange(null, '')}>
                  {words['Show all']}
                </Button>
                <div className="flex flex-1 justify-end">
                  <Link
                    to="/dashboard/add-visitor"
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1"
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">
                      {words['Add visitor']}
                    </label>
                  </Link>
                </div>
              </div>
              <div style={{ marginTop: -20 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={filteredVisitors}
                  columns={columns}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
        <DeleteConfirmationModel
          title=""
          desc="Are you sure you want to mark as paid?"
          openModal={openDeleteModal}
          onSubmit={() => doChangeStatus()}
          setOpenModal={setOpenDeleteModal}
        />
      </Modal>
    </Layout>
  );
};

export default Records;
