import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const visitorService = createApi({
  reducerPath: "visitors",
  tagTypes: "visitors",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {



      getVisitors: builder.query({
        query: () => {
          return {
            url: "/api/visitors",
            method: "GET",
          };
        },
        providesTags: ["visitors"],
      }),


      addVisitor: builder.mutation({
        query: (data) => {
          return {
            url: "/api/visitors",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["visitors"],
      }),

     




    };
  },
});
export const { useAddVisitorMutation,useGetVisitorsQuery } = visitorService;
export default visitorService;
