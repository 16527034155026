import React, { useEffect, useState } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import SwapVert from "@mui/icons-material/SwapVert";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TextField,
    Link,
    Button,
    Dropdown,
    Menu,
    MoreOutlined,
} from '@mui/material';
import { SearchSettingIcon } from '../assets/Icons';


const DataTable = ({
    draggable,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDrop,
    data = [],
    columns,
    searchColoumns,
    searchContainerStyle,
    sortKey = null,
    sortDirection = null
}) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: sortKey ?? null, direction: sortDirection ?? 'ascending' });
    const [dataTemp, setDataTemp] = useState(data);


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        // let filterData = data.filter((item) => searchColoumns.some((coloumn) => item[coloumn].toLowerCase().includes(event.target.value.toLowerCase())));
        // setDataTemp(filterData);    

    };

    const handleSort = (key, dir) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction: dir ?? direction });
    };

    const sortedData = [...data].sort((a, b) => {
        if (sortConfig.key !== null) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const filteredData = sortedData.filter((item) =>
        Object.values(item).some(
            (value) => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    useEffect(() => {
        if (sortKey && sortDirection) {
            handleSort(sortKey, sortDirection)
        }
    }, [])

    return (
        <div style={{ width: "98%", }}>

            <div
                style={{...searchContainerStyle,backgroundColor:'#F0F6F9',borderWidth:0}}
                className="flex items-center gap-3 w-full rounded-full border border-[#D1D5DB] px-3 py-2 focus-within:border-themeColor anim">
                <div className="flex items-center justify-center h-5 w-5">
                    <SearchIcon />
                </div>
                <input
                    onChange={handleSearch}
                    type="text"
                    placeholder={"Search"}
                    style={{fontFamily:'serif'}}
                    className="w-full cleanbtn text-base font-normal"
                />
                {/* <div className="flex items-center justify-center h-5 w-5">
                    <SearchSettingIcon />
                </div> */}
            </div>




            <TableContainer
                style={{ marginTop: 10, borderRadius: 20 }}

                component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#E0E9FF', }}>
                        <TableRow >
                            {columns?.map((column) => (
                                <TableCell
                                    // style={{ fontFamily: 'Poppins', fontSize: 14, color: 'black' }}
                                    className="px-2 py-2 text-left text-sm text-black font-medium"
                                    key={column.key} onClick={() => handleSort(column.dataIndex)}>
                                    <div
                                        className="px-2 py-2 text-left text-sm text-black font-medium"
                                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',fontFamily:'MSBo',color:'black' }}>
                                        {column.title}
                                        <SwapVert style={{ fontSize: 15 }} />
                                    </div>

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData?.map((row, index) => (
                            <TableRow
                                draggable={draggable}
                                onDragStart={(e) => onDragStart(e, row)}
                                onDragEnd={onDragEnd}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                }}
                                onDrop={(e) => onDrop(e, row)}
                                key={index}>
                                {columns.map((column) => (
                                    <TableCell

                                        style={{ fontSize: 12, }}
                                        className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]"
                                        key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default DataTable;
