// src/components/BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ labels, dataSet }) => {
    const data = {
        labels: labels ?? ['10AM-11AM', '11AM-12PM', '12PM-1PM', '1PM-2PM', '2PM-3PM', '3PM-4PM', '4PM-5PM', '5PM-6PM', '6PM-7PM'],
        datasets: [
            {
                label: 'Visitors',
                data: dataSet ?? [65, 59, 80, 81, 56, 55, 440],
                backgroundColor: '#A473F5',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Rush Hours',
            },
        },
    };

    return <div style={{ width: "50%" }}>
        <Bar data={data} options={options} />;
    </div>
};

export default BarChart;
