import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

const CustomCalender = ({ onDateChange = () => { } }) => {

  const isInitialMount = React.useRef(true);


  const manths = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  const today = new Date();
  const [date, setDate] = useState(today);

  // useEffect(() => {
  //   // Alert when the date changes
  //   onDateChange(date);
  // }, [date]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onDateChange(date);
    }
  }, [date]);




  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const handlePrevMonth = () => {
    const prevMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    setDate(new Date(prevMonthDate.getFullYear(), prevMonthDate.getMonth(), 1));
  };

  const handleNextMonth = () => {
    const nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setDate(new Date(nextMonthDate.getFullYear(), nextMonthDate.getMonth(), 1));
  };

  const handleDayClick = (day) => {
    setDate(new Date(date.getFullYear(), date.getMonth(), day));
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    }).format(date);
  };

  const daysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
  const firstDayOfMonth = getFirstDayOfMonth(date.getFullYear(), date.getMonth());
  const currentDay =  date.getDate();

  const previousMonthDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

  const generateDaysArray = () => {
    const daysArray = [];
    for (let i = 1; i <= previousMonthDays; i++) {
      daysArray.push(null); // Empty space for previous month's days
    }
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    return daysArray;
  };

  const daysArray = generateDaysArray();

  return (
    <div className="wrap wrapWidth">
      <div
        style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '20px' }}
      >
        <div style={{ marginTop: -80 }}>
          <section className="calendar">
            <div className="calendar-top">
              <div className="calendar-top-info">
                <h3 className="calendar-title">Reservation Calendar</h3>
                <p className="calendar-data">{formatDate(date)}</p>
              </div>
              <div className="calendar-top-arrows">
                <button onClick={handlePrevMonth} className="calendar-top-arrows-button">
                  <img src="/images/arrow.svg" alt="arrow" />
                </button>
                <button onClick={handleNextMonth} className="calendar-top-arrows-button">
                  <img src="/images/arrow.svg" alt="arrow" />
                </button>
              </div>
            </div>
            <ul className="calendar-listWeek">
              {manths.map(el => (
                <li key={el}>{el}</li>
              ))}
            </ul>
            <ul className="calendar-listDays">
              {daysArray.map((day, index) => (
                <li
                  key={index}
                  onClick={() => day && handleDayClick(day)}
                  className={`
                    ${day === currentDay ? 'selected' : ''}
                  `}
                >
                  {day || ''}
                </li>
              ))}
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CustomCalender;
