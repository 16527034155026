


import { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useAdminLoginMutation } from "../../store/services/authService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAdminToken } from "../../store/reducers/authReducer";
import { EyeIcon } from "../../assets/Icons";
import { useAdminLoginMutation } from "../../store/services/authService";

const Login = () => {

  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const [login, response] = useAdminLoginMutation();;
  // 

  const [showPassword, setShowPassword] = useState(false);
  const adminLoginFunction = (e) => {

    
    e.preventDefault();
    console.log('e',state)
    login(state);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message || "");
      localStorage.setItem('admin', response.data?.admin);
      localStorage.setItem("admin-token", response?.data?.token);
      dispatch(setAdminToken(response?.data?.token));
      navigate("/dashboard/home");
    }
    if (response.isError) {
      console.log('response?.error',response?.error)
      toast.error(response?.error?.data?.error || "");
    }
  }, [response]);

  return (
    <div className="bg-white min-h-screen flex items-center justify-center p-4">
      <div className="flex w-full h-full max-w-screen-lg gap-4">
        <div className="hidden md:flex items-center justify-center w-full md:w-1/2 h-full">
          <img src="/images/login-bg.png" className="h-full w-full object-contain rounded-xl" />
        </div>


        <form
          className="flex flex-col gap-6 w-full md:w-1/2 bg-loginFormBg p-6 rounded-xl h-full justify-center"
          onSubmit={adminLoginFunction}
        >
          <div className="flex items-center justify-center mt-12">
            <label
              style={{ fontFamily: 'MSBo', fontSize: 35, color: '#5A00ED' }}
              className="block text-lg font-light leading-6 text-black">Welcome</label>
          </div>
          <div className="flex items-center justify-center py-7">
            <label
              style={{ fontFamily: 'MSBo', fontSize: 25, color: '#18120F' }}
              className="block text-lg font-light leading-6 text-black">Your Event Journey Start Here</label>
          </div>

          <div className="flex flex-col gap-2">

            <div style={{ display: 'flex' }}>
              <div style={{ position: 'absolute', zIndex: 1, marginTop: 5, marginLeft: 5 }}>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="20" fill="#D7D6EB" />
                  <path d="M19.2099 21.6816C15.6589 22.0026 12.9535 25.0035 13.0006 28.5691V28.7499C13.0006 29.4403 13.5602 29.9999 14.2505 29.9999C14.9407 29.9999 15.5003 29.4403 15.5003 28.7499V28.5191C15.4627 26.3299 17.0782 24.4629 19.2499 24.1858C21.5423 23.9585 23.585 25.6327 23.8123 27.9254C23.8258 28.061 23.8326 28.1971 23.8327 28.3333V28.75C23.8327 29.4403 24.3923 30 25.0825 30C25.7728 30 26.3324 29.4403 26.3324 28.75V28.3333C26.3283 24.6472 23.3372 21.6624 19.6516 21.6665C19.5042 21.6667 19.3569 21.6717 19.2099 21.6816Z" fill="black" />
                  <path d="M19.6666 20C22.4277 20 24.666 17.7614 24.666 15C24.666 12.2386 22.4277 10 19.6666 10C16.9055 10 14.6672 12.2386 14.6672 15C14.6699 17.7603 16.9066 19.9972 19.6666 20ZM19.6666 12.5C21.0471 12.5 22.1663 13.6193 22.1663 15C22.1663 16.3807 21.0471 17.5 19.6666 17.5C18.2861 17.5 17.1669 16.3807 17.1669 15C17.1669 13.6193 18.2861 12.5 19.6666 12.5Z" fill="black" />
                  <path d="M31.3317 16.5625C30.3663 16.6106 29.6213 17.4301 29.6652 18.3958C29.7091 17.4301 28.9642 16.6106 27.9987 16.5625C27.0333 16.6106 26.2884 17.4301 26.3322 18.3958C26.3322 19.8375 28.212 21.5266 29.1486 22.2783C29.4505 22.5201 29.8798 22.5201 30.1818 22.2783C31.1184 21.5283 32.9981 19.8375 32.9981 18.3958C33.042 17.4301 32.2971 16.6106 31.3317 16.5625Z" fill="black" />
                </svg>
              </div>
              <input
                style={{ borderRadius: 30, height: 52, paddingLeft: 50 }}
                type="email"
                name="email"
                className="input"
                onChange={handleInputs}
                value={state.email}
                required
                placeholder={words["Your email"]}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div
              style={{ borderRadius: 30, paddingLeft: 5, height: 52 }}
              className="input flex items-center gap-1">

              <div>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="20" fill="#D7D6EB" />
                  <path d="M27 17.155V15.7508C27 12.0225 23.866 9 20 9C16.134 9 13 12.0225 13 15.7508V17.155C11.1608 18.0601 10.0023 19.8824 10 21.8738V26.6958C10.0033 29.6239 12.4638 31.9968 15.5 32H24.5C27.5362 31.9968 29.9967 29.6239 30 26.6958V21.8738C29.9978 19.8825 28.8392 18.0601 27 17.155ZM20 11.8932C22.2091 11.8932 24 13.6203 24 15.7508V16.5696H16V15.7508C16 13.6203 17.7909 11.8932 20 11.8932ZM27 26.6958C27 28.0274 25.8808 29.1068 24.5001 29.1068H15.5C14.1193 29.1068 13.0001 28.0274 13.0001 26.6958V21.8738C13.0001 20.5423 14.1193 19.4628 15.5 19.4628H24.5001C25.8808 19.4628 27 20.5423 27 21.8738V26.6958Z" fill="black" />
                  <path d="M19.5 22.356H20.5C21.3284 22.356 22 23.0036 22 23.8026C22 24.6015 21.3284 25.2492 20.5 25.2492H19.5C18.6716 25.2492 18 24.6015 18 23.8026C18 23.0036 18.6716 22.356 19.5 22.356Z" fill="black" />
                </svg>
              </div>


              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="w-full"
                onChange={handleInputs}
                value={state.password}
                required
                placeholder={words["Type your password"]}
              />
              <button
                type="button"
                className="flex items-center justify-center"
                onClick={(e) => {
                  setShowPassword(!showPassword);
                }}
              >
                <EyeIcon />
              </button>
            </div>
          </div>
          <div className="mt-12 mb-3">
            <input
              type="submit"
              value={response.isLoading ? words["Loading"] + "..." : words["Sign in"]}
              className="bg-themeColor w-full px-4 py-3 rounded-full text-white text-base font-medium cursor-pointer"
            />
          </div>
          <div style={{ marginTop: 133 ,fontFamily:'MSBo',fontSize:14,color:'#18120F',textAlign:'center'}} >
            2024 Event Plus  All Rights Reserved.
          </div>
        </form>

        {/* IMAGE DIV */}

      </div>
    </div>






  );
};

export default Login;
