// import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Input, Layout, ToggleButton, UploadButton } from "../../components";
import { useCreateStoreMutation } from "../../store/services/storeService";
import { postRequestFormData } from "../../Api";
import { useSelector } from "react-redux";
import MapComponent from "../../components/MapComponent";
import { MAP_API_KEY } from "../../Api/constants";
import addVisitorImg from '../../assets/images/addVisitor.png';
import { BorderRight, Height } from '@mui/icons-material';
import QRCode from 'react-qr-code';
import { useAddVisitorMutation } from '../../store/services/visitorsService';


const hearedFromOptions = [
    "SMS",
    "Social media",
    "Website",
    "Friends",
    "Influencers",
    "Billboards",
    "Radio",
    "Other"
];

const genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' },
];

const types = [
    { value: 'Individual', label: 'Individual' },
    { value: 'Family', label: 'Family' },
];

const root = {
    backgroundColor: '#ECF2FF',
    borderWidth: 1,
    borderColor: '#979797',
    borderStyle: 'solid',
    borderRadius: 44,
    height: 35, // Set the height to 35 pixels
    '& .MuiOutlinedInput-input': {
        padding: '2px 14px', // Adjust padding as needed for content alignment
    },
}
const inputStyle = {
    backgroundColor: '#ECF2FF', borderWidth: 1, borderColor: '#979797', borderRadius: 4, height: 55
}



const AddVisitor = () => {




    const words = useSelector((state) => state.authReducer.words);
    const navigate = useNavigate();

    const [saveVisitor, response] = useAddVisitorMutation();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [type, setType] = useState('');
    const [maleMembers, setMaleMembers] = useState('');
    const [femaleMembers, setFemaleMembers] = useState('');
    const [hearedFrom, setHearedFrom] = useState('');










    const handleSubmitSetting = async (e) => {

        e.preventDefault();



        const body = {
            full_name: name,
            phone: phone,
            email: email,
            gender: gender,
            type: type,
            heared_source: hearedFrom,
            male_members: type == 'Family' ? maleMembers : 0,
            female_members: type == 'Family' ? femaleMembers : 0,
            card_scanned: 0,
            added_by: 'admin',
            reg_status: '0',
        }

        saveVisitor(body);
    };

    useEffect(() => {

        console.log('response', response);
        if (response.isSuccess) {
            toast.success("Data Save Successfully");
            navigate("/dashboard/visitors");
        }
        if (response.isError) {
            toast.error(response.error?.data?.error || "Something wrong try again later!");
        }
    }, [response]);




    return (
        <Layout pageTitle={words["Add visitor"]}>

            {/* addVisitorImg */}



            <div className="w-full min-h-screen flex pb-10">

                <div className="wrap wrapWidth flex flex-col gap-6">
                    <img src={addVisitorImg} />
                    <form onSubmit={handleSubmitSetting} className="w-full gap-6">

                        <div className="w-full flex gap-6 mb-6">
                            <div
                                style={{ backgroundColor: '#ECF2FF', padding: 100 }}
                                className="w-full grid-box px-5 pt-4 pb-12 border-solid border-[1px] border-[#D8D8D8] rounded-[20px] flex flex-col">

                                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    <div
                                        className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                        <div className="text-base text-black">{words["Full Name"]}</div>
                                        <Input
                                            style={inputStyle}
                                            placeholder={words["Enter the Full Name"]}
                                            name="full_name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                        <div className="text-base text-black">{words["Phone"]}</div>
                                        <Input
                                            placeholder={words["Phone"]}
                                            style={inputStyle}
                                            name="store_visting_charges"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    <div
                                        className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                        <div className="text-base text-black">{words["Gender"]}</div>

                                        <TextField
                                            select
                                            label={words["Select gender"]}
                                            value={gender}
                                            onChange={(event) => setGender(event.target.value)}
                                            variant="outlined"
                                            style={{
                                                ...inputStyle, height: 35, // Set the height to 35 pixels
                                                padding: 0

                                            }}
                                            className={root}
                                        >
                                            {genders.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                        <div className="text-base text-black">{words["type"]}</div>
                                        <TextField
                                            select
                                            label={words["Select type"]}
                                            value={type}
                                            onChange={(event) => setType(event.target.value)}
                                            variant="outlined"
                                            style={{
                                                ...inputStyle, height: 35, // Set the height to 35 pixels
                                                padding: 0

                                            }}
                                            className={root}
                                        >
                                            {types.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                                {
                                    type == 'Family' &&


                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 20 }}>
                                        <div
                                            className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                            <div  style={{display:'flex',alignItems:'center'}} className="text-base text-black">{words["Male family members"]}<div style={{ fontSize: 9,fontFamily:'MRe',marginLeft:4 }}>(Other than you)</div> </div>
                                            <Input
                                                // placeholder="Other th    an you"
                                                min={0}
                                                style={inputStyle}
                                                type='number'
                                                name="full_name"
                                                value={maleMembers}
                                                onChange={(e) => setMaleMembers(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                        <div  style={{display:'flex',alignItems:'center'}} className="text-base text-black">{words["Female family members"]}<div style={{ fontSize: 9,fontFamily:'MRe',marginLeft:4 }}>(Other than you)</div> </div>
                                            <Input
                                                min={0}
                                                type='number'
                                                style={inputStyle}
                                                name="store_visting_charges"
                                                value={femaleMembers}
                                                onChange={(e) => setFemaleMembers(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                }


                                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 20 }}>
                                    <div
                                        className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                        <div className="text-base text-black">{words["email"]}</div>
                                        <Input
                                            style={inputStyle}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>


                                    <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                        <div className="text-base text-black">{words["How did you know about the Exhibition?"]}</div>
                                        <TextField
                                            select
                                            label={words["How did you know about the Exhibition?"]}
                                            value={hearedFrom}
                                            onChange={(event) => setHearedFrom(event.target.value)}
                                            variant="outlined"
                                            style={{
                                                ...inputStyle, height: 35, // Set the height to 35 pixels
                                                padding: 0

                                            }}
                                            className={root}
                                        >
                                            {hearedFromOptions.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>


                                </div>

                            </div>
                        </div>




                        <div className="w-full flex items-center justify-end">

                            <input
                                type="submit"
                                value={response.isLoading ? "Loading..." : words["Save"]}
                                className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full cursor-pointer"
                            />
                        </div>
                    </form>
                </div>
            </div >
        </Layout >
    );



};

export default AddVisitor;
