import React from "react";

function ReorderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="16"
      fill="none"
      viewBox="0 0 9 16"
    >
      <path
        fill="#D0934D"
        d="M4.519 0l3.638 7.5H.88L4.52 0zM4.519 16L.88 8.5h7.277L4.52 16z"
      ></path>
    </svg>
  );
}

export default ReorderIcon;
