
export const ar = {
    'home': 'بيت',
    'users': 'مستخدمين',
    'stores': 'متاجر',
    'bookings': 'طلبات',
    'finance': 'المعاملات',
    'banners': 'الاعلانات',
    'featured': 'الاقسام',
    'notifications': 'الاشعارات',
    'settings': 'الاعدادات',
    'logout': 'خروج',
    'income': 'دخل',

    'no': 'لا',
    'store name': 'اسم المتجر',
    'phone': 'رقم الهاتف',
    'email': 'البريد الالكتروني',
    'id store': 'رمز المتجر',
    'location': 'الموقع',
    'publishing': 'منشور',
    'status': 'الحالة',
    'action': 'اعداد',
    'type': 'نوع',
    'phone': 'رقم الهاتف',
    'id': 'رمز',
    'image': 'صورة',
    'name': 'اسم',
    'search': 'بحث',
    'add store': 'اضافة متجر',
    'publishing': 'منشور',
    'general': 'عام',
    'store information': 'معلومات المتجر',
    'service list': 'قائمة الخدمات',
    'product list': 'قائمة المنتجات',
    'store details': 'تفاصيل المتجر',

    'income': 'دخل',
    'products': 'منتجات',
    'orders': 'طلبات',
    'services': 'خدمات',
    'evaluation': 'تقييمات',
    "OMR": 'ر.ع',

    'Store Name': 'اسم المتجر',
    'Visiting Charge': 'تكلفة الزيارة',
    'Store Name': 'اسم المتجر',
    'Address': 'العنوات',
    'City': 'المدينة',
    'At Home': 'في المنزل',
    'At Store': 'في المتجر',
    'About Company': 'عن المتجر',
    'Long Description': 'وصف طويل',
    'Name': 'الاسم',
    'Email': 'البريد الالكتروني',
    'Phone': 'رقم الهاتف',
    'Commission': 'العمولة',
    'Sunday': 'الاحد',
    'Monday': 'الاثنين',
    'Tuesday': 'الثلاثاء',
    'Wednesday': 'الاربعاء',
    'Thursday': 'الخميس',
    'Friday': 'الجمعة',
    'Saturday': 'السبت',
    'Bank Name': 'اسم البنك',
    'Account Number': 'رقم الحساب',
    'Account Name': 'اسم الحساب',

    'Logo': 'شعار',
    'Banner': 'لوحة',
    'Company Details': 'تفاصيل المتجر',
    'Bank Details': 'بيانات البنك',
    'Personal Information': 'معلومات شخصية',
    'Edit Store': 'تعديل المتجر',
    'Active': 'فعال',
    'featured sections': 'الاقسام',

    'Visiting Charges': 'تكلفة الزيارة',
    'At Store': 'في المتجر',
    'At Home': 'في المنزل',
    'About this store': 'عن هذا المتجر',
    'Working Days': 'ايام العمل',
    'Images': 'الصور',
    'Photos': 'الصور',
    'Location Information': 'معلومات الموقع',
    'Current Location': 'الموقع الحالي',
    'CR Certification': 'السجل التجاري',
    'ID Card': 'البطاقة الشخصية',
    "To": "الى",
    "on": 'مفتوح',
    "off": 'مغلق',
    "Map": "خريطة",
    'Phone Number': 'رقم الهاتف',
    "save": "حفظ",
    'Create store': 'انشاء متجر',

    'Your email': 'بريدك الالكتروني',
    'Type your password': 'الرمز السري',
    'Password': 'الرمز السري',
    'Sign in': 'تسجيل',
    'Loading': 'تحميل',
    'Created at': 'تم الانشاء في',
    'Edit': 'تعديل',
    "Add": "اضافة",
    'Delete': 'حذف',
    'Edit user': 'تعديل المستخدم',
    'Add user': 'اضافة مستخدم',

    'Setting': 'الاعدادات',
    'Terms & Privacy Settings': 'شروط الاستخدام',
   

    'Customer Terms and Conditions': 'شروط الاستخدام',
    'Customer privacy policy': 'بيانات الخصوصية',
    'Store Terms and Conditions': 'شروط الاستخدام للمتاجر',
    'Store Privacy Policy': 'بيانات الخصوصية للمتاجر',

    'Notification': 'الاشعارات',
    'Title': 'العنوان',
    'Description': 'الوصف',
    'Date': 'التاريخ',

    'Featured Section': 'الاقسام',
    'Rank': 'الترتيب',
    'Add Featured Section': 'اضافة قسم',
    'Section Types': 'نوع القسم',
    'Add Featured': 'اضافة قسم',
    'Add Banner': 'اضافة اعلان',
    'Select': 'اختيار',
    'Store': 'متجر',
    'User': 'متسخدم',
    'Edit Banner': 'تعديل اعلان',

    'Order Id': 'رمز الطلب',
    'User name': 'اسم المستخدم',
    'Order amount': 'قيمة الطلب',
    'Admin commision': 'عمولة الشركة',
    'Store amount': 'مبلغ المتجر',
    'Transaction date': 'تاريخ التحويل',
    'Is Paid to store': 'هل دفعت للمتجر',
    'Paid': 'مدفوع',
    'Mark as paid': 'اقرار الدفع',

    'Booking Detail': 'تفاصيل الطلب',
    'Date of order': 'تاريخ الطلب',
    'Payment status': 'حالة الدفع',
    'Price': 'المبلغ',
    'Quantity': 'العدد',
    'Customer Details': 'بيانات العميل',
    'Contact Number': 'رقم التواصل',
    'Co %' : 'نسبة %',
    'Total' : 'الاجمالي',
    'Store Info' : 'معلومات المتجر',
    'Company Name' : 'اسم المتجر',
    'User phone': 'رقم العميل',
    'Paymnet status' : 'حالة الدفع',
    'View' : 'اطلاع',
    'Timing Details' : 'تفاصيل التوقيت',
    'Are you sure you want to delete this store?' :'هل انت متاكد من حذف المتجر؟',
    'Yes' : 'نعم',
    'No' : 'لا',
    'Cancel' : 'الغاء',
    'Service Name' : 'اسم الخدمة',
    'ID Store' : 'رمز المتجر',
    'Detail' : 'التفاصيل',
    'View Service' : 'مشاهدة الخدمة',
    'Service Details' : 'تفاصيل الخدمة',
    'Service Price': 'قيمة الخدمة',
    'Basic Details' : 'تفاصيل اساسية',
    'Tamsir Type' : 'نوع التمصيرة',
    'Tamsir Color' : 'لون التمصيرة',
    'Tamsir Accessory': 'اكسسوارات التمصيرة',
    'Service description' : 'وصف الخدمة',
    'Product Name' : 'اسم المنتج',
    'Product Details' : 'تفاصيل المنتج',
    'View Product' : 'مشاهدة المنتج ',





    'Show all' : 'عرض الكل',





















}