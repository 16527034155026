import { configureStore } from "@reduxjs/toolkit";


// import storeService from "./services/storeService";
import userService from "./services/userService";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import authService from "./services/authService";
import visitorService from "./services/visitorsService";
import organizerService from "./services/organizerService";
import exhibitorService from "./services/exhibitorService";

const Store = configureStore({
  reducer: {

    // [storeService.reducerPath]: storeService.reducer,
    [userService.reducerPath]: userService.reducer,
    [authService.reducerPath]: authService.reducer,
    [visitorService.reducerPath]: visitorService.reducer,
    [organizerService.reducerPath]: organizerService.reducer,
    [exhibitorService.reducerPath]: exhibitorService.reducer,

    authReducer: authReducer,
    globalReducer: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([

      // storeService.middleware,
      userService.middleware,
      authService.middleware,
      organizerService.middleware,
      visitorService.middleware,
      exhibitorService.middleware
    ]),
});

export default Store;
