const ToggleButton = ({ checked, setChecked }) => {
  return (
    <button
      type="button"
      className={`w-[52px] h-[24px] ${checked ? "bg-themeColor/30": "bg-themeColor/29"}  rounded-full border-[1px] border-themeColor flex items-center relative`}
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <div
        className={`h-[17px] w-[17px] rounded-full bg-themeColor absolute animation ${
          checked ? "left-7" : "left-1"
        }`}
      />
    </button>
  );
};

export default ToggleButton;
