// src/components/DoughnutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChartMarketingReach = ({ count }) => {
  // Define all categories with fallback values
  const defaultCounts = {
    "SMS": 0,
    "Social media": 0,
    "Website": 0,
    "Friends": 0,
    "Influencers": 0,
    "Billboards": 0,
    "Radio": 0,
    "Other": 0,
  };

  // Merge the actual counts with default values
  const actualCounts = { ...defaultCounts, ...count };

  const labels = [
    `SMS: ${actualCounts["SMS"]}`,
    `Social media: ${actualCounts["Social media"]}`,
    `Website: ${actualCounts["Website"]}`,
    `Friends: ${actualCounts["Friends"]}`,
    `Influencers: ${actualCounts["Influencers"]}`,
    `Billboards: ${actualCounts["Billboards"]}`,
    `Radio: ${actualCounts["Radio"]}`,
    `Other: ${actualCounts["Other"]}`,
  ];

  const data = [
    actualCounts["SMS"],
    actualCounts["Social media"],
    actualCounts["Website"],
    actualCounts["Friends"],
    actualCounts["Influencers"],
    actualCounts["Billboards"],
    actualCounts["Radio"],
    actualCounts["Other"],
  ];

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: [
          '#1352F1',
          '#741DE3',
          '#17C198',
          '#44A615',
          '#BE17C1',
          '#EAA800',
          '#39CAEA',
          '#D12B2B',
        ],
        hoverBackgroundColor: [
          '#5A00ED',
          '#7C71FD',
          '#00BFAE',
          '#58BF23',
          '#CE1C75',
          '#F1C300',
          '#26A9D2',
          '#C82333',
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const total = tooltipItem.dataset.data.reduce((a, b) => a + b, 0);
            const value = tooltipItem.raw;
            const percentage = ((value / total) * 100).toFixed(2);
            return `${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  return <div style={{ width: '80%', height: '300px',position:'absolute',display:'flex',flexWrap:'wrap',overflow:'hidden',marginLeft:-45 }}>
    <Doughnut data={chartData} options={options} />
  </div>;
};

export default DoughnutChartMarketingReach;
