
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Layout, ToggleButton, UploadButton } from "../../components";

import { useSelector } from "react-redux";

import { ImageIcon } from '../../assets/Icons';
import { useAddExhibitorMutation } from "../../store/services/exhibitorService";
import { toast } from "react-toastify";



const hearedFromOptions = [
    "SMS",
    "Social media",
    "Website",
    "Friends",
    "Influencers",
    "Billboards",
    "Radio",
    "Other"
];

const genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' },
];

const types = [
    { value: 'Individual', label: 'Individual' },
    { value: 'Family', label: 'Family' },
];


const inputStyle = {
    borderWidth: 1, borderColor: '#C5C5C5', borderRadius: 4,
}

const labelStyle = {
    fontFamily: 'IBo',
    fontSize: 14,
    color: '#000000',

}





const AddExhibitor = () => {




    const words = useSelector((state) => state.authReducer.words);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [saveExhibitor, response] = useAddExhibitorMutation();

    const [img, setImg] = useState();
    const [ownerPic, setOwnerPic] = useState();




    const [gender, setGender] = useState('');
    const [type, setType] = useState('');
    const [hearedFrom, setHearedFrom] = useState('');

    const inputs = [
        {
            name: words["Name of campany  or Shop"], state: 'company_name'
        },
        {
            name: words["CR Number"], state: 'cr_number'
        },
        {
            name: words["Email of camany  "], state: 'company_email'
        },
        {
            name: words["Phone Number"], state: 'company_phone'
        },
        {
            name: words["Business Type"], state: 'business_type'
        },
        {
            name: words["Address / City"], state: 'company_city'
        },
        {
            name: words["Number of Products"], state: 'company_no_of_products'
        },
        {
            name: words["Number of Employees"], state: 'company_no_of_employees'
        },

    ];

    const ownerInputs = [
        {
            name: words["Owner Full Name"], state: 'owner_name'
        },
        // {
        //     name: words["ID Number"], state: 'owner_email'
        // },
        {
            name: words["Phone Number"], state: 'owner_phone'
        },
        {
            name: words["Personal Email"], state: 'owner_email'
        },
    ]

    const [formInputs, setFormInputs] = useState({
        company_name: '',
        cr_number: '',
        company_email: '',
        company_phone: '',
        business_type: '',
        company_city: '',
        company_no_of_products: '',
        company_no_of_employees: '',

        owner_name: '',
        owner_email: '',
        owner_phone: '',
        owner_email: '',


    })


    const onChnage = (state, value) => {
        console.log('state', state)
        setFormInputs({
            ...formInputs,
            [state]: value
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formInputs.company_name) {
            return toast.error('Please enter company name')
        }
        if (!formInputs.cr_number) {
            return toast.error('Please enter company number')
        }
        if (!formInputs.company_email) {
            return toast.error('Please enter company email')
        }
        if (!formInputs.company_phone) {
            return toast.error('Please enter company phone')
        }
        if (!formInputs.business_type) {
            return toast.error('Please enter company type')
        }
        if (!formInputs.company_city) {
            return toast.error('Please enter company city')
        }
        if (!formInputs.company_no_of_products) {
            return toast.error('Please enter company number of products')
        }
        if (!formInputs.company_no_of_employees) {
            return toast.error('Please enter company no of employees')
        }


        if (!formInputs.owner_name) {
            return toast.error('Please enter owner name')
        }
        if (!formInputs.owner_email) {
            return toast.error('Please enter owner email')
        }
        if (!formInputs.owner_phone) {
            return toast.error('Please enter owner phone')
        }
        if (!formInputs.owner_email) {
            return toast.error('Please enter owner email')
        }

        const body = {
            ...formInputs,
            owner_pic: ownerPic,
            company_logo: img,
            company_address: formInputs.company_city
        }
        const formData = new FormData();


        for (const [key, value] of Object.entries(body)) {
            formData.append(key, body[key])
            console.log('here is the key: ', key, '  _ value:', body[key])
        }



        saveExhibitor(formData)


    }



    useEffect(() => {
        console.log('response', response);
        if (response.isSuccess) {
            toast.success("Data Save Successfully");
            navigate("/dashboard/exhibitors");
        }
        if (response.isError) {
            toast.error("Something wrong try again later!");
        }
    }, [response]);




    return (
        <Layout pageTitle={words["Add exhibitor"]}>




            <div className="w-full min-h-screen flex pb-10">

                <div className="wrap wrapWidth flex flex-col gap-6">

                    <form
                        onSubmit={handleSubmit}
                        className="w-full gap-6">
                        <div style={{ width: "100%", height: 10, backgroundColor: '#7C71FD', borderTopRightRadius: 10, borderTopLeftRadius: 10 }} />

                        <div
                            style={{ marginTop: -10 }}
                            className="w-full flex gap-6 mb-6">

                            <div
                                className="w-full grid-box  pt-4 pb-12 border-solid border-[0px] border-[#D8D8D8] rounded-[10px] flex flex-col">
                                <div style={{ fontFamily: 'IMe', fontSize: 19, color: '#43434C', marginTop: 10, marginLeft: 15 }}>{words["Exhibitor Information"]} </div>
                                <div style={{ width: "100%", height: 1, backgroundColor: '#BEBABA', marginTop: 10, marginBottom: 10 }} />
                                <div className="px-8">
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                        <div style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: '#5A00ED' }} />
                                        <div style={{ fontFamily: 'MSBo', fontSize: 17, color: '#6B6B6B', marginLeft: 10 }}>{words["Company Information"]} </div>
                                    </div>
                                    <div style={{ marginTop: 10 }} />


                                    <div className='flex' >
                                        <div className="flex ">
                                            <div className="field flex flex-col">
                                                <div
                                                    className="img-box flex items-center justify-center h-40 w-64 bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
                                                    onClick={() => document.getElementById("upload_img").click()}
                                                >
                                                    {img ? (
                                                        <img
                                                            alt="img"
                                                            src={URL.createObjectURL(img)}
                                                            className="h-full w-full overflow-hidden object-contain"
                                                        />
                                                    ) : (
                                                        <div className="flex items-center justify-center h-full w-full">
                                                            <ImageIcon />
                                                        </div>
                                                    )}
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        title=""
                                                        name="image"
                                                        id="upload_img"
                                                        className="hidden cleanbtn"
                                                        onChange={(e) => {
                                                            setImg(e.target.files[0]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginLeft: 10 }}>
                                            {
                                                inputs.map((v, i) => {
                                                    return <div
                                                        className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                                        <div
                                                            style={labelStyle}
                                                            className="text-base text-black">{v.name}</div>
                                                        <Input
                                                            onChange={(t) => onChnage(v.state, t.target.value)}
                                                            value={formInputs[v.state]}
                                                            style={inputStyle}
                                                            // placeholder={words["Enter the Full Name"]}
                                                            name={v.name}
                                                        // required
                                                        />
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div style={{ width: "100%", height: 1, backgroundColor: '#BEBABA', marginTop: 10, marginBottom: 10 }} />

                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                        <div style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: '#5A00ED' }} />
                                        <div style={{ fontFamily: 'MSBo', fontSize: 17, color: '#6B6B6B', marginLeft: 10 }}>{words["Owner  Information "]} </div>
                                    </div>
                                    <div style={{ marginTop: 10 }} />


                                    <div className='flex' >
                                        <div className="flex ">
                                            <div className="field flex flex-col">
                                                <div
                                                    className="img-box flex items-center justify-center h-40 w-64 bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
                                                    onClick={() => document.getElementById("upload_owner_pic").click()}
                                                >
                                                    {ownerPic ? (
                                                        <img
                                                            alt="img"
                                                            src={URL.createObjectURL(ownerPic)}
                                                            className="h-full w-full overflow-hidden object-contain"
                                                        />
                                                    ) : (
                                                        <div className="flex items-center justify-center h-full w-full">
                                                            <ImageIcon />
                                                        </div>
                                                    )}
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        title=""
                                                        name="image"
                                                        id="upload_owner_pic"
                                                        className="hidden cleanbtn"
                                                        onChange={(e) => {
                                                            setOwnerPic(e.target.files[0]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginLeft: 10 }}>
                                            {
                                                ownerInputs.map((v, i) => {
                                                    return <div
                                                        style={{ marginLeft: 10 }}
                                                        className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                                        <div
                                                            style={labelStyle}
                                                            className="text-base text-black">{v.name}</div>
                                                        <Input
                                                            onChange={(t) => onChnage(v.state, t.target.value)}

                                                            style={{ ...inputStyle }}

                                                            // placeholder={words["Enter the Full Name"]}
                                                            name={v.name}
                                                        // required
                                                        />
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>





                                </div>
                            </div>
                        </div>




                        <div className="w-full flex items-center justify-end">
                          
                            <input
                                type="submit"
                                value={response.isLoading ? "Loading..." : loading ? "Loading..." : words["Save"]}
                                className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full cursor-pointer"
                            />
                        </div>
                    </form>
                </div>
            </div >
        </Layout >
    );



};

export default AddExhibitor;
