import { LineChart } from '@mui/x-charts/LineChart';

import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import {
    CrossIcon,
    DotMenuIcon,
    IncomeIcon,
    StoreIcon,
    UsersIcon,
} from "../../assets/Icons";


import { BASE_URL } from "../../Api/constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getRequest, postRequest, postRequestFormData } from "../../Api";
import { routes } from "../../Api/routes";
import Modal from "../../components/Modal";
import WelcomeImg from '../../assets/images/welcomeImage.png';
import CustomPickersDay from '../../components/Calender/CustomCalender';
import { PieChart } from '@mui/x-charts';
import BarChart from '../../components/BarChart';
import DoughnutChart from '../../components/DoughnutChart';
import CustomCalender from '../../components/Calender/CustomCalender';
import { getDateString } from '../../utils/functions';
import DoughnutChartMarketingReach from '../../components/DoughnutChartMarketingReach';
import AppLoader from '../../components/Loader/AppLoader';

const Analytics = () => {

    const words = useSelector((state) => state.authReducer.words)
    const lang = useSelector((state) => state.authReducer.lang)
    const classTextAlign = lang == 'ar' ? 'text-right' : 'text-left';

    const [loading, setLoading] = useState(false);

    const [totalVisitors, setTotalVisitors] = useState()
    const [totalOrganizers, setTotalOrganizers] = useState()
    const [totalExhibitors, setTotalExhibitors] = useState()
    const [totalMaleVisitors, setTotalMaleVisitors] = useState()
    const [totalFemaleVisitors, setTotalFemaleVisitors] = useState()
    const [rushHoursTitle, setRushHoursTitle] = useState([]);
    const [rushHoursValues, setRushHoursValues] = useState([]);
    const [totalVisitorsByFamily, setTotalVisitorByFamily] = useState();
    const [totalIndividualVisitor, setTotalIndividualVisitor] = useState();
    const [hearedSourceCount, setHearedSourceCount] = useState({});



    const getDashboard = async (date = null) => {


        const onSuccess = (res) => {
            setLoading(false);
            if (res.data) {
                setTotalVisitors(res.data.totalVisitors);
                setTotalOrganizers(res.data.totalOrganizers);
                setTotalExhibitors(res.data.totalExhibitors);
                setTotalMaleVisitors(res.data.totalMaleVisitors);
                setTotalFemaleVisitors(res.data.totalFemaleVisitors);
                setTotalVisitorByFamily(res.data.totalIndividuals);
                setTotalIndividualVisitor(res.data.totalFamily);
                setHearedSourceCount(res.data.heardFromCounts)

                let rushhours = res.data.rushHours;
                let arr = [];
                let arr1 = [];

                for (const [key, value] of Object.entries(rushhours)) {
                    console.log(key[0] + ', ' + key[1]);
                    arr.push(key)
                    arr1.push(value);
                }



                setRushHoursTitle(arr);
                setRushHoursValues(arr1);

            }


        }

        const onError = () => {
            setLoading(false);
        }

        setLoading(true);

        function getFormatedDate(date) {
            if (date) return new Date(date);
            else new Date();
            // new Date();
        }


        const formattedDate = getFormatedDate(date);


        await postRequestFormData({ date: formattedDate }, '/api/getDashboardData', true, onSuccess, onError)

    }




    useEffect(() => {
        getDashboard();
    }, [])






    return (
        <>
            {
                loading && <AppLoader />
            }
            <Layout pageTitle={words["Analytics"]} >
                <div className="lading-page h-screen flex">

                    <div className="wrap wrapWidth ">
                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginTop: -40 }}
                        >
                            <div style={{ width: "50%" }}>

                                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                                    <div style={{ width: "32%", borderRadius: 10, height: 150, backgroundColor: '#DDFADF', marginTop: 20, display: 'flex' }}>
                                        <div style={{ margin: 10 }}>
                                            <svg width="42" height="42" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.15" width="62" height="62" rx="10" fill="#47A000" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.7085 18.7135C23.5679 18.7135 21.0406 21.1701 21.0406 24.1966C21.0406 27.1468 23.427 29.5413 26.4383 29.6641C26.6116 29.6518 26.7954 29.6513 26.9663 29.664C29.9753 29.54 32.3627 27.1468 32.3764 24.1948C32.3754 21.1705 29.835 18.7135 26.7085 18.7135ZM19.2694 24.1966C19.2694 20.2263 22.5871 17 26.7085 17C30.8138 17 34.1476 20.2251 34.1476 24.1966L34.1476 24.2001C34.1309 28.0845 30.9664 31.2486 26.9744 31.3784C26.9351 31.3797 26.8957 31.3784 26.8565 31.3746C26.7701 31.3663 26.6496 31.3655 26.5377 31.3753C26.5012 31.3786 26.4645 31.3796 26.4278 31.3784C22.4364 31.2486 19.2694 28.0843 19.2694 24.1966Z" fill="#47A000" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.7601 20.7125C36.7601 20.2394 37.1566 19.8558 37.6458 19.8558C41 19.8558 43.6974 22.4827 43.6974 25.7101C43.6974 28.872 41.1042 31.4468 37.8708 31.5639C37.823 31.5656 37.7752 31.5636 37.7278 31.5579C37.6865 31.5529 37.6238 31.5515 37.5517 31.5593C37.0656 31.6115 36.6277 31.2726 36.5737 30.8024C36.5197 30.3321 36.87 29.9085 37.3561 29.8563C37.5221 29.8384 37.695 29.8349 37.8638 29.8491C40.123 29.7372 41.9262 27.9263 41.9262 25.7101C41.9262 23.4259 40.0185 21.5693 37.6458 21.5693C37.1566 21.5693 36.7601 21.1857 36.7601 20.7125Z" fill="#47A000" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9631 32.9745C29.7754 32.9745 32.6319 33.6578 34.8306 35.0781C36.8011 36.3465 37.9041 38.1059 37.9041 39.9766C37.9041 41.8468 36.8015 43.6093 34.8321 44.8847C32.6264 46.3124 29.7657 47 26.952 47C24.1387 47 21.2786 46.3127 19.073 44.8854C17.1028 43.617 16 41.8577 16 39.9873C16 38.117 17.1025 36.3545 19.0719 35.0791L19.0752 35.077C21.2873 33.6581 24.1502 32.9745 26.9631 32.9745ZM20.0556 36.504C18.4542 37.5417 17.7712 38.8201 17.7712 39.9873C17.7712 41.1543 18.454 42.429 20.0551 43.4595L20.0571 43.4607C21.9104 44.6603 24.4078 45.2865 26.952 45.2865C29.4963 45.2865 31.9937 44.6603 33.847 43.4607C35.4494 42.4229 36.1328 41.1441 36.1328 39.9766C36.1328 38.8095 35.45 37.5348 33.8489 36.5044L33.8476 36.5035C32.0021 35.3111 29.5084 34.688 26.9631 34.688C24.4188 34.688 21.9171 35.3106 20.0556 36.504Z" fill="#47A000" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.6748 34.8031C39.7823 34.3415 40.2563 34.0516 40.7334 34.1557C41.8752 34.4046 42.9878 34.847 43.9219 35.5363C45.2633 36.5108 46 37.8575 46 39.268C46 40.6781 45.2637 42.0245 43.9229 42.999C42.9765 43.7009 41.8468 44.1614 40.6751 44.3975C40.1963 44.494 39.7272 44.1967 39.6275 43.7335C39.5277 43.2703 39.835 42.8166 40.3138 42.7201C41.2659 42.5282 42.1421 42.1614 42.8481 41.637L42.8561 41.6311C43.8153 40.9352 44.2288 40.0696 44.2288 39.268C44.2288 38.4663 43.8153 37.6007 42.8561 36.9048L42.8499 36.9004C42.1611 36.3914 41.297 36.035 40.3441 35.8272C39.8669 35.7232 39.5673 35.2647 39.6748 34.8031Z" fill="#47A000" />
                                            </svg>
                                        </div>
                                        <div style={{ color: '18120F', fontFamily: 'MBo', fontSize: 20, alignSelf: 'center', marginTop: 50, display: 'flex', textAlign: 'center' }}>{totalVisitors} Visitors</div>
                                    </div>
                                    <div style={{ width: "32%", borderRadius: 10, height: 150, backgroundColor: '#FFFACA', marginTop: 20, display: 'flex' }}>
                                        <div style={{ margin: 10 }}>
                                            <svg width="42" height="42" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.15" width="62" height="62" rx="10" fill="#F3A40C" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.317 32.924C21.317 31.5182 22.4556 30.3798 23.8613 30.3798H27.7363V31.2571H28.6137V40.4108C28.6137 40.8954 28.2209 41.2882 27.7363 41.2882H22.1944C21.7098 41.2882 21.317 40.8954 21.317 40.4108V32.924ZM26.8589 32.1345H23.8613C23.4246 32.1345 23.0717 32.4874 23.0717 32.924V39.5335H26.8589V32.1345Z" fill="#F35F0C" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8462 26.2707C26.8462 24.865 27.9847 23.7264 29.3905 23.7264H31.6132C33.029 23.7264 34.1429 24.8751 34.1429 26.2707V40.3963C34.1429 40.8808 33.7501 41.2736 33.2655 41.2736H27.7235C27.239 41.2736 26.8462 40.8808 26.8462 40.3963V26.2707ZM29.3905 25.4811C28.9539 25.4811 28.6009 25.834 28.6009 26.2707V39.5189H32.3882V26.2707C32.3882 25.8239 32.0398 25.4811 31.6132 25.4811H29.3905Z" fill="#F35F0C" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.3982 33.7429C32.3982 33.2583 32.791 32.8655 33.2756 32.8655H37.1506C38.5493 32.8655 39.6728 34.0088 39.6948 35.396L39.6949 35.4099V40.3963C39.6949 40.8808 39.3021 41.2736 38.8176 41.2736H33.2756C32.791 41.2736 32.3982 40.8808 32.3982 40.3963V33.7429ZM34.1529 34.6202V39.5189H37.9402V35.4181C37.93 34.9656 37.5633 34.6202 37.1506 34.6202H34.1529Z" fill="#F35F0C" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6332 19.6332C19.5232 17.7431 22.371 17 26.1132 17H34.8868C38.629 17 41.4768 17.7431 43.3668 19.6332C45.2569 21.5232 46 24.371 46 28.1132V36.8868C46 40.629 45.2569 43.4768 43.3668 45.3668C41.4768 47.2569 38.629 48 34.8868 48H26.1132C22.371 48 19.5232 47.2569 17.6332 45.3668C15.7431 43.4768 15 40.629 15 36.8868V28.1132C15 24.371 15.7431 21.5232 17.6332 19.6332ZM18.8739 20.8739C17.4739 22.2739 16.7547 24.5441 16.7547 28.1132V36.8868C16.7547 40.4559 17.4739 42.7261 18.8739 44.1261C20.2739 45.5261 22.5441 46.2453 26.1132 46.2453H34.8868C38.4559 46.2453 40.7261 45.5261 42.1261 44.1261C43.5261 42.7261 44.2453 40.4559 44.2453 36.8868V28.1132C44.2453 24.5441 43.5261 22.2739 42.1261 20.8739C40.7261 19.4739 38.4559 18.7547 34.8868 18.7547H26.1132C22.5441 18.7547 20.2739 19.4739 18.8739 20.8739Z" fill="#F35F0C" />
                                            </svg>
                                        </div>
                                        <div style={{ color: '18120F', fontFamily: 'MBo', fontSize: 20, alignSelf: 'center', marginTop: 50, display: 'flex', textAlign: 'center' }}>{totalExhibitors} Exhibitors </div>
                                    </div>
                                    <div style={{ width: "32%", borderRadius: 10, height: 150, backgroundColor: '#FBCCE9', marginTop: 20, display: 'flex' }}>
                                        <div style={{ margin: 10 }}>
                                            <svg width="42" height="42" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.15" width="62" height="62" rx="10" fill="#A0009A" />
                                                <path d="M26.7354 34.1275C26.7354 35.7892 28.0149 37.1255 29.5844 37.1255H32.7915C34.1563 37.1255 35.2652 35.9606 35.2652 34.5044C35.2652 32.9455 34.5829 32.3802 33.5763 32.0204L28.4413 30.2217C27.4348 29.8619 26.7524 29.3137 26.7524 27.7377C26.7524 26.2986 27.8613 25.1166 29.2261 25.1166H32.4333C34.0028 25.1166 35.2823 26.4528 35.2823 28.1145" stroke="#A00090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M31.0002 23.4206V38.8386" stroke="#A00090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M48.0599 31.1296C48.0599 40.5859 40.4172 48.2607 31.0002 48.2607C21.5832 48.2607 13.9404 40.5859 13.9404 31.1296C13.9404 21.6733 21.5832 13.9985 31.0002 13.9985" stroke="#A00090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M39.53 15.7116V22.5641H46.3539" stroke="#A00090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M48.0599 13.9985L39.53 22.5641" stroke="#A00090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </div>
                                        <div style={{ color: '18120F', fontFamily: 'MBo', fontSize: 20, alignSelf: 'center', marginTop: 50, display: 'flex', textAlign: 'center', marginLeft: -5 }}>{totalOrganizers} Organizers </div>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 20, }}>
                                        <div style={{ backgroundColor: '#E0E9FF', width: 250, height: 100, borderRadius: 10, paddingLeft: 20, paddingTop: 20 }}>
                                            <div style={{ fontFamily: 'MMe', fontSize: 16, color: '#6B6B6B', }}>Visitor registered as Family</div>
                                            <div style={{ fontFamily: 'MBo', fontSize: 32, color: '#18120F', marginTop: 5 }}>{totalVisitorsByFamily}</div>
                                        </div>
                                        <div style={{ backgroundColor: '#E0E9FF', width: 250, height: 100, borderRadius: 10, paddingLeft: 20, paddingTop: 20, marginLeft: 10 }}>
                                            <div style={{ fontFamily: 'MMe', fontSize: 16, color: '#6B6B6B', }}>Individual Visitors</div>
                                            <div style={{ fontFamily: 'MBo', fontSize: 32, color: '#18120F', marginTop: 5 }}>{totalIndividualVisitor}</div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div style={{ marginTop: -20, width: 500 }}>
                                <CustomCalender onDateChange={(date) => getDashboard(date)} />
                            </div>
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginTop: 20 }}>

                            <BarChart dataSet={rushHoursValues} labels={rushHoursTitle} />

                            <div style={{ display: 'flex', marginLeft: 10, marginTop: 20 }}>
                                {/* <div style={{ marginLeft: 30 }}></div> */}
                                <div style={{ backgroundColor: '#E0E9FF', width: 250, height: 300, borderRadius: 10, paddingLeft: 20, paddingTop: 20 }}>
                                    <DoughnutChart
                                        maleMembers={totalMaleVisitors} totalFemaleMembers={totalFemaleVisitors} />
                                </div>
                                <div style={{ backgroundColor: '#E0E9FF', width: 250, height: 340, borderRadius: 10, paddingLeft: 20, paddingTop: 20, marginLeft: 20 }}>
                                    <DoughnutChartMarketingReach
                                        count={hearedSourceCount}
                                        maleMembers={totalMaleVisitors} totalFemaleMembers={totalFemaleVisitors} />
                                </div>
                                {/* <div>
                  <div style={{ backgroundColor: '#E0E9FF', width: 250, height: 100, borderRadius: 10, paddingLeft: 20, paddingTop: 20 }}>
                    <div style={{ fontFamily: 'MMe', fontSize: 16, color: '#6B6B6B', }}>Active users</div>
                    <div style={{ fontFamily: 'MBo', fontSize: 32, color: '#18120F', marginTop: 5 }}>10,786</div>
                  </div>
                  <div style={{ backgroundColor: '#E0E9FF', width: 250, height: 100, borderRadius: 10, marginTop: 10, paddingLeft: 20, paddingTop: 20 }}>
                    <div style={{ fontFamily: 'MMe', fontSize: 16, color: '#6B6B6B', }}>Active users</div>
                    <div style={{ fontFamily: 'MBo', fontSize: 32, color: '#18120F', marginTop: 5 }}>10,786</div>

                  </div>
                  <div style={{ backgroundColor: '#E0E9FF', width: 250, height: 100, borderRadius: 10, marginTop: 10, paddingLeft: 20, paddingTop: 20 }}>
                    <div style={{ fontFamily: 'MMe', fontSize: 16, color: '#6B6B6B', }}>Active users</div>
                    <div style={{ fontFamily: 'MBo', fontSize: 32, color: '#18120F', marginTop: 5 }}>10,786</div>
                  </div>
                </div> */}
                                {/* <div style={{ backgroundColor: '#E0E9FF', width: 200, height: "100%", borderRadius: 10, marginLeft: 10 }}>
                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </Layout >
        </>
    );
};

export default Analytics;
