import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import QRCode from 'react-qr-code';
import { useForceUpdate } from '../../utils/functions';
import Loader from '../../components/Loader/AppLoader';
import { CrossIcon } from '../../assets/Icons';


pdfMake.vfs = pdfFonts.pdfMake.vfs;






const TicketComponent = ({ name, phone, ticketNo, qrLink, close = () => { } }) => {

    const forceUpdate = useForceUpdate();
    const [loading, setLoading] = useState(false);
    const [divHeight, setDivHeight] = useState('641.89px');
    const ticketRef = useRef(null);

    const handleDownload = () => {

        setLoading(true);
        setDivHeight('841.89px');
        forceUpdate();

        setTimeout(() => {
            const ticketElement = ticketRef.current;

            // Convert the ticket element to canvas with a higher scale for better quality
            html2canvas(ticketElement, { scale: 6 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');

                // Define A4 dimensions in points (1 inch = 72 points)
                const a4Width = 595.28;
                const a4Height = 841.89;

                const docDefinition = {
                    pageSize: { width: a4Width, height: a4Height },
                    pageMargins: [0, 0, 0, 0],
                    content: [
                        {
                            image: imgData,
                            width: a4Width,
                            height: a4Height,
                            alignment: 'center',
                        },
                    ],
                    defaultStyle: {
                        fontSize: 12,
                        alignment: 'center'
                    }
                };

                pdfMake.createPdf(docDefinition).download('ticket.pdf');
                setDivHeight('641.89px');
                setLoading(false);
                close();
            });
        }, 500);

    };


    return (
        <div>
            {
                loading && <Loader />
            }
            {/* <div
                onClick={() => close()}
                style={{
                    position: 'absolute', alignSelf: 'flex-end',
                    cursor: 'pointer', margin: 'auto', textAlign: 'center',
                }}
            >
                <CrossIcon />
            </div> */}
            <div
                ref={ticketRef}
                style={{ margin: 'auto', width: "595.28px", height: divHeight, backgroundColor: '#EAF2FF', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>



                <div style={{ backgroundColor: '#7C71FD', padding: '25px', borderRadius: '10px', textAlign: 'center', marginBottom: '20px' }}>
                    <h2 style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>Your Ticket</h2>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <p style={{ marginTop: 10 }}><strong>Full Name:</strong> {name}</p>
                    <p style={{ marginTop: 10 }}><strong>Phone:</strong> {phone}</p>
                    <p style={{ marginTop: 10 }}><strong>Ticket No.:</strong> {ticketNo}</p>
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 134, width: "100%", marginTop: 20 }}>
                        <QRCode
                            size={100}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={qrLink ?? "asd"}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    {
                        divHeight == '641.89px' &&

                        <button
                            onClick={handleDownload}
                            style={{
                                display: 'flex', alignSelf: 'center',
                                backgroundColor: '#5A00ED', color: 'white',
                                padding: '7px 20px', borderRadius: '10px', border: 'none',
                                fontSize: '18px', cursor: 'pointer', margin: 'auto', marginTop: '130px', textAlign: 'center',
                            }}>
                            Download PDF
                        </button>
                    }
                </div>


            </div>

            {/* <div style={{position:'absolute'}}> */}
            {/* <Printablecomponent ticketRef={ticketRef} /> */}
            {/* </div> */}
        </div>
    );
};

export default TicketComponent;
