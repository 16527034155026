import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { setOpenSidebar } from "../store/reducers/globalReducer";

const Layout = ({ children, pageTitle }) => {
  const { openSidebar } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  const lang = useSelector((state)=> state.authReducer.lang);
  

  return (
    <div
      style={{ marginLeft: !openSidebar ? 0 : lang == 'ar' ? 0:  280,marginRight:!openSidebar? 0: lang =='en' ? 0: 280 }}
      className={`dashboard-page flex flex-col relative p-2 ${openSidebar ? "" : "full-open"
        }`}
    >
      {/* <button
        onClick={() => dispatch(setOpenSidebar(!openSidebar))}
      >
      toggle sidebar
      </button> */}
      <Sidebar openSidebar={openSidebar} />
      <div className="pages-block flex flex-col relative h-full">
        <Header pageTitle={pageTitle} />
        <section>{children}</section>
        {/* <Footer /> */}
      </div>
    </div>
  );
};
export default Layout;
