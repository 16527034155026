// src/components/DoughnutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';




ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ maleMembers, totalFemaleMembers }) => {
  const total_male_members = 20;
  const total_female_members = 100;

  const data = {
    labels: [
      `Male Members: ${maleMembers ?? 0}`,
      `Female Members: ${totalFemaleMembers ?? 0}`,
    ],
    datasets: [
      {
        data: [maleMembers, totalFemaleMembers],
        backgroundColor: ['#5A00ED', '#7C71FD'],
        hoverBackgroundColor: ['#5A00ED', '#7C71FD'],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const total = tooltipItem.dataset.data.reduce((a, b) => a + b, 0);
            const value = tooltipItem.raw;
            const percentage = ((value / total) * 100).toFixed(2);
            return `${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  return <Doughnut

    data={data} options={options} />;
};

export default DoughnutChart;
