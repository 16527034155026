import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Private from "./Private";
import Public from "./Public";

import Login from "../Pages/Login";
import Registration from "../Pages/Registration";

import Home from "../Pages/Home";

import Users from "../Pages/Users";
import Records from "../Pages/Visitors";
import AddVisitor from "../Pages/Visitors/AddVisitor";
import Organizers from "../Pages/Organizers";
import Exhibitors from "../Pages/Exhibitors";
import AddExhibitor from "../Pages/Exhibitors/AddExhibitor";
import BadgeScreen from "../Pages/Badge";
import EditExhibitor from "../Pages/Exhibitors/EditExhibitor";
import ViewExhibitor from "../Pages/Exhibitors/ViewExhibitor";
import Analytics from "../Pages/Analytics";


const Routing = () => {
  return (
    <BrowserRouter>
      <ToastContainer position="top-center" />
      <Routes>
        <Route
          path="/"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route path="auth">
          <Route
            path="login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="register"
            element={
              <Public>
                <Registration />
              </Public>
            }
          />
        </Route>
        <Route path="dashboard">
          <Route
            path="home"
            element={
              <Private>
                <Home />
              </Private>
            }
          />

          <Route
            path="analytics"
            element={
              <Private>
                <Analytics />
              </Private>
            }
          />


          <Route
            path="visitors"
            element={
              <Private>
                <Records />
              </Private>
            }
          />
          <Route
            path="add-visitor"
            element={
              <Private>
                <AddVisitor />
              </Private>
            }
          />

          <Route
            path="organizers"
            element={
              <Private>
                <Organizers />
              </Private>
            }
          />


          <Route
            path="exhibitors"
            element={
              <Private>
                <Exhibitors />
              </Private>
            }
          />

          <Route
            path="add-exhibitor"
            element={
              <Private>
                <AddExhibitor />
              </Private>
            }
          />
          <Route
            path="edit-exhibitor/:id"
            element={
              <Private>
                <EditExhibitor />
              </Private>
            }
          />
          <Route
            path="view-exhibitor/:id"
            element={
              <Private>
                <ViewExhibitor />
              </Private>
            }
          />


          <Route
            path="badge"
            element={
              <Private>
                <BadgeScreen />
              </Private>
            }
          />





          <Route
            path="users"
            element={
              <Private>
                <Users />
              </Private>
            }
          />



        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
