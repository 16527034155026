import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const storeService = createApi({
  reducerPath: "store",
  tagTypes: "stores",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createStore: builder.mutation({
        query: (data) => {
          return {
            url: "api/stores/store",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["stores"],
      }),

      updateTamsirType: builder.mutation({
        query: (data) => {
          return {
            url: "api/stores/services/edit_service_tamsir_type",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["stores"],
      }),

      updateTamsirColor: builder.mutation({
        query: (data) => {
          return {
            url: "api/stores/services/edit_service_tamsir_color",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["stores"],
      }),

      updateTamsirAcc: builder.mutation({
        query: (data) => {
          return {
            url: "api/stores/services/edit_service_tamsir_acc",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["stores"],
      }),

      updateService: builder.mutation({
        query: (data) => {
          return {
            url: "api/stores/services/edit_service",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["stores"],
      }),


      updateProduct: builder.mutation({
        query: (data) => {
          return {
            url: "api/stores/product/edit_product",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["stores"],
      }),

      updateProductImages: builder.mutation({
        query: (data) => {
          return {
            url: "api/stores/product/edit_product_images",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["stores"],
      }),




      editStore: builder.mutation({
        query: (data) => {
          return {
            url: "api/stores/edit",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["stores"],
      }),

      getStores: builder.query({
        query: () => {
          return {
            url: "/api/stores",
            method: "GET",
          };
        },
        providesTags: ["stores"],
      }),

      fetchStore: builder.query({
        query: (id) => {
          return {
            url: `/api/stores/find/${id}`,
            method: "GET",
          };
        },
        providesTags: ["stores"],
      }),
      fetchStoreServices: builder.query({
        query: (id) => {
          return {
            url: `/api/stores/services/${id}`,
            method: "GET",
          };
        },
        providesTags: ["stores"],
      }),
      fetchSingleService: builder.query({
        query: (id) => {
          return {
            url: `/api/services/find/${id}`,
            method: "GET",
          };
        },
        providesTags: ["stores"],
      }),

      fetchStoreProducts: builder.query({
        query: (id) => {
          return {
            url: `/api/stores/products/${id}`,
            method: "GET",
          };
        },
        providesTags: ["stores"],
      }),
      fetchSingleProduct: builder.query({
        query: (id) => {
          return {
            url: `/api/products/find/${id}`,
            method: "POST",
          };
        },
        providesTags: ["stores"],
      }),

      deleteService: builder.mutation({
        query: (id) => {
          return {
            url: `/api/stores/services/delete/${id}`,
            method: "POST",
          };
        },
        invalidatesTags: ["stores"],
      }),
      deleteProduct: builder.mutation({
        query: (id) => {
          return {
            url: `/api/stores/products/delete/${id}`,
            method: "POST",
          };
        },
        invalidatesTags: ["stores"],
      }),


    };
  },
});
export const {
  useCreateStoreMutation,
  useUpdateTamsirTypeMutation,
  useUpdateTamsirColorMutation,
  useUpdateTamsirAccMutation,
  useUpdateProductImagesMutation,
  useUpdateServiceMutation,
  useUpdateProductMutation,
  useEditStoreMutation,
  useGetStoresQuery,
  useFetchStoreQuery,
  useFetchStoreServicesQuery,
  useFetchSingleServiceQuery,
  useFetchStoreProductsQuery,
  useFetchSingleProductQuery,
  useDeleteServiceMutation,
  useDeleteProductMutation,
} = storeService;
export default storeService;
