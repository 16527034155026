import React from "react";

function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="16"
      fill="none"
      viewBox="0 0 28 16"
    >
      <path
        fill="#5A00ED"
        d="M14.001 16C7.28 16.01.668 12.42.668 8c0-4.419 6.645-8.023 13.333-8 6.688.023 13.334 3.581 13.334 8 0 4.419-6.611 7.99-13.334 8zm0-2.667a5.333 5.333 0 100-10.666 5.333 5.333 0 000 10.666zm0-2.666a2.667 2.667 0 110-5.334 2.667 2.667 0 010 5.334z"
      ></path>
    </svg>
  );
}

export default EyeIcon;
