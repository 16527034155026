import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const organizerService = createApi({
  reducerPath: "organizers",
  tagTypes: "organizers",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {



      getOrganizers: builder.query({
        query: () => {
          return {
            url: "/api/organizers",
            method: "GET",
          };
        },
        providesTags: ["organizers"],
      }),


      addOrganizer: builder.mutation({
        query: (data) => {
          return {
            url: "/api/organizers",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["organizers"],
      }),


      deleteOrganizer: builder.mutation({
        query: (id) => {
          return {
            url: "/api/organizers/" + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["organizers"],
      }),






    };
  },
});
export const { useAddOrganizerMutation, useGetOrganizersQuery, useDeleteOrganizerMutation } = organizerService;
export default organizerService;