
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Layout, ToggleButton, UploadButton } from "../../components";

import { useSelector } from "react-redux";

import { ImageIcon } from '../../assets/Icons';
import { useAddExhibitorMutation, useGetExhibitorsQuery } from "../../store/services/exhibitorService";
import { toast } from "react-toastify";

import badgeBackground from '../../assets/images/badgeBackground.png'
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import { useForceUpdate } from '../../utils/functions';
import Loader from '../../components/Loader';
import AppLoader from '../../components/Loader/AppLoader';





pdfMake.vfs = pdfFonts.pdfMake.vfs;



const BadgeScreen = () => {


    const { data: exhibitorList, isLoading: bookingLoading } = useGetExhibitorsQuery();

    const forceUpdate = useForceUpdate();
    const [loading, setLoading] = useState(false);
    const [divHeight, setDivHeight] = useState('641.89px');

    const words = useSelector((state) => state.authReducer.words);
    const navigate = useNavigate();

    const badgeRef = useRef();

    const [saveExhibitor, response] = useAddExhibitorMutation();

    const [exhibitorImg, setExhibitorImg] = useState();
    const [companyLogo, setCompanyLogo] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [title1, setTitle1] = useState('');
    const [title2, setTitle2] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const [base64Img, setBase64Img] = useState('');


    const filteredOptions = exhibitorList?.data?.filter((exhibitor) =>
        `${exhibitor.company_name} || ${exhibitor.company_email}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setIsOpen(true); // Open the dropdown as the user starts typing
    };

    const handleOptionClick = (exhibitor) => {
        setSelectedOption(`${exhibitor.company_name} || ${exhibitor.company_email}`);
        setCompanyLogo(exhibitor.company_logo)
        fetchImage(BASE_URL + "/" + exhibitor.company_logo);
        setIsOpen(false); // Close the dropdown after selecting an option
    };






    const handleDownload = () => {

        setLoading(true);
        setDivHeight('841.89px');
        // forceUpdate();

        setTimeout(() => {
            const ticketElement = badgeRef.current;

            html2canvas(ticketElement, { scale: 6 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const a4Width = 595.28;
                const a4Height = 841.89;

                const docDefinition = {
                    // pageSize: { width: a4Width, height: a4Height },
                    pageMargins: [0, 0, 0, 0],
                    content: [
                        {
                            image: imgData,
                            width: a4Width,
                            // height: a4Height,
                            alignment: 'center',
                        },
                    ],
                    defaultStyle: {
                        fontSize: 12,
                        alignment: 'center'
                    }
                };

                pdfMake.createPdf(docDefinition).download('ticket.pdf');
                setDivHeight('641.89px');
                setLoading(false);
            });
        }, 500);

    };



    const fetchImage = async (imageUrl) => {
        try {
            // const corsProxy = 'https://cors-anywhere.herokuapp.com/'; // Or any other CORS proxy you prefer
            // const proxiedUrl = `${corsProxy}${imageUrl}`;


            const response = await fetch(imageUrl);

            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {

                console.log('base64', reader.result)
                setBase64Img(reader.result);
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };



    useEffect(() => {
        console.log('response', response);
        if (response.isSuccess) {
            toast.success("Data Save Successfully");
            navigate("/dashboard/exhibitors");
        }
        if (response.isError) {
            toast.error("Something wrong try again later!");
        }
    }, [response]);






    return (
        <>
            {
                loading && <AppLoader />
            }

            <Layout pageTitle={words["Badge"]}>

                <div className="w-full min-h-screen flex pb-10">

                    <div className="wrap wrapWidth flex flex-col gap-6">
                        <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>

                            <div style={{
                                width: '50%',
                                height: '85%',
                            }} >

                                {/* <div style={{ width: "100%", borderRadius: 10, backgroundColor: '#5A00ED', height: 120 }}>

                            </div> */}

                                <div style={{ width: "100%", borderRadius: 10, backgroundColor: '#E0E9FF', padding: 20, marginTop: 20 }}>
                                    <div style={{ fontFamily: 'IBo', fontSize: 16, color: '#000000' }}>Select Exhibitor</div>

                                    <input
                                        type="text"
                                        placeholder="Search exhibitor"
                                        value={searchTerm}
                                        onChange={handleInputChange}
                                        onFocus={() => setIsOpen(true)}
                                        className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
                                        style={{ backgroundColor: 'transparent', width: '100%', borderColor: '#979797', marginTop: 10 }}
                                    />
                                    {isOpen && (
                                        <div
                                            className="absolute mt-1 w-full bg-white rounded-lg border border-gray-300 shadow-lg z-10"
                                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                                        >
                                            {filteredOptions?.map((exhibitor, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handleOptionClick(exhibitor)}
                                                    className="cursor-pointer py-2 px-4 hover:bg-gray-200"
                                                >
                                                    {`${exhibitor.company_name} || ${exhibitor.company_email}`}
                                                </div>
                                            ))}
                                        </div>
                                    )}



                                    {/* <Input
                                    style={{ backgroundColor: '#E0E9FF', borderWidth: 1, borderColor: '#979797', marginTop: 10 }}
                                /> */}
                                </div>

                                <div style={{ width: "100%", borderRadius: 10, backgroundColor: '#E0E9FF', padding: 20, marginTop: 20 }}>
                                    <div style={{ fontFamily: 'IBo', fontSize: 16, color: '#000000' }}>Employee name</div>
                                    <Input
                                        onChange={(e) => setEmployeeName(e.target.value)}
                                        style={{ backgroundColor: '#E0E9FF', borderWidth: 1, borderColor: '#979797', marginTop: 10 }}
                                    />
                                </div>


                                <div style={{ width: "100%", borderRadius: 10, backgroundColor: '#E0E9FF', padding: 20, marginTop: 20 }}>
                                    <div style={{ fontFamily: 'IBo', fontSize: 16, color: '#000000' }}>Title</div>
                                    <Input
                                        onChange={(e) => setTitle1(e.target.value)}
                                        style={{ backgroundColor: '#E0E9FF', borderWidth: 1, borderColor: '#979797', marginTop: 10 }}
                                    />
                                    <Input
                                        onChange={(e) => setTitle2(e.target.value)}
                                        style={{ backgroundColor: '#E0E9FF', borderWidth: 1, borderColor: '#979797', marginTop: 10 }}
                                    />
                                </div>

                                <div style={{ width: "100%", borderRadius: 10, backgroundColor: '#E0E9FF', padding: 20, marginTop: 20 }}>

                                    <div
                                        onClick={() => document.getElementById("exhibitor_image").click()}
                                        className="cursor-pointer"

                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="54" height="54" rx="14" fill="#5A00ED" />
                                            <path d="M33.729 24.216C33.456 20.7428 30.5422 18 27 18C23.97 18 21.369 19.968 20.5297 22.8383C18.1358 23.6693 16.5 25.9373 16.5 28.5C16.5 31.8082 19.1918 34.5 22.5 34.5H32.25C35.145 34.5 37.5 32.145 37.5 29.25C37.5 26.8883 35.9475 24.861 33.729 24.216ZM30.693 26.5373C30.5767 26.817 30.303 27 30 27H28.5V30C28.5 30.828 27.828 31.5 27 31.5C26.172 31.5 25.5 30.828 25.5 30V27H24C23.697 27 23.4233 26.817 23.307 26.5373C23.1908 26.2568 23.2552 25.9343 23.4698 25.7198L26.4698 22.7198C26.616 22.5735 26.808 22.5 27 22.5C27.192 22.5 27.384 22.5735 27.5303 22.7198L30.5303 25.7198C30.7448 25.9343 30.8092 26.2568 30.693 26.5373Z" fill="white" />
                                        </svg>

                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div style={{ color: '#8E8B8B', fontSize: 12, fontFamily: 'IBo' }}>Upload Exhibitor employees image</div>
                                            <div style={{ color: '#8E8B8B', fontSize: 12, fontFamily: 'IMe' }}>150X150 pixel </div>

                                            <input
                                                type="file"
                                                accept="image/*"
                                                title=""
                                                name="image"
                                                id="exhibitor_image"
                                                className="hidden cleanbtn"
                                                onChange={(e) => {
                                                    setExhibitorImg(e.target.files[0]);
                                                }}
                                            />


                                        </div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button
                                        onClick={handleDownload}
                                        style={{
                                            display: 'flex', alignSelf: 'center',
                                            backgroundColor: '#5A00ED', color: 'white',
                                            padding: '7px 20px', borderRadius: '10px', border: 'none',
                                            fontSize: '18px', cursor: 'pointer', margin: 'auto', marginTop: '30px', textAlign: 'center',
                                        }}>
                                        Download PDF
                                    </button>
                                    {/* <button
                                        // onClick={handleDownload}
                                        style={{
                                            display: 'flex', alignSelf: 'center',
                                            backgroundColor: '#5A00ED', color: 'white',
                                            padding: '7px 20px', borderRadius: '10px', border: 'none',
                                            fontSize: '18px', cursor: 'pointer', margin: 'auto', marginTop: '30px', textAlign: 'center',
                                        }}>
                                        Print
                                    </button> */}
                                </div>
                            </div>


                            <div
                                ref={badgeRef}

                                style={{
                                    // backgroundImage: `url(${badgeBackground})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    width: '50%',
                                    height: divHeight,

                                    // '85%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    // justifyContent: 'center',
                                    flexDirection: 'column',

                                }}>
                                {
                                    companyLogo &&

                                    <img
                                        style={{ objectFit: 'contain', width: 120, height: 120, marginTop: 20 }}
                                        // src={BASE_URL + "/" + companyLogo}
                                        src={IMAGE_URL + companyLogo}
                                    />
                                }
                                {/* {
                                    base64Img &&

                                    <img
                                        style={{ objectFit: 'contain', width: 120, height: 120, marginTop: 20 }}
                                        src={'data:image;base64,'+base64Img}
                                    />
                                } */}
                                {
                                    exhibitorImg &&

                                    <img
                                        style={{ width: 120, height: 120, marginTop: 20, borderRadius: 10 }}
                                        src={URL.createObjectURL(exhibitorImg)}

                                    />
                                }
                                {
                                    employeeName &&
                                    <div style={{ color: '#8E8B8B', fontSize: 17, fontFamily: 'IBo', marginTop: 40, }}>{employeeName}</div>
                                }

                                {
                                    title1.length ?
                                        <Input
                                            value={title1}
                                            disabled={true}
                                            style={{ backgroundColor: 'transparent', borderWidth: 1, borderColor: '#979797', marginTop: 30, width: "50%", }}
                                        /> : null
                                }
                                {
                                    title2.length ?
                                        <Input
                                            value={title2}
                                            disabled={true}
                                            style={{ backgroundColor: 'transparent', borderWidth: 1, borderColor: '#979797', marginTop: 20, width: "50%", }}
                                        /> : null

                                }
                            </div>
                        </div>
                    </div >
                </div >
            </Layout >

        </>
    );



};

export default BadgeScreen;
