import React from "react";


function removePublicFromUrl(url = "") {
  return url?.slice(6, url.length);
}
//  CAPATILIAZE THE FIRST LETTER OF STRING
const capitalizeFirstLetter = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

function getDateString(date) {
  try {
    date = new Date(date);
    let day = '';
    const year = date.getFullYear();
    let month = '';
    if (date.getMonth() + 1 < 10) {
      month = '0' + (date.getMonth() + 1);
    } else {
      month = date.getMonth() + 1;
    }
    if (date.getDate() < 10) {
      day = '0' + date.getDate();
    } else {
      day = date.getDate();
    }

    const dateStr = month + '-' + day + '-' + year;
    return dateStr;
  } catch (e) {
    console.log(e);
  }
}




function getTimeString(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


// TO FORCE THE COMPONENT TO UPDATE, TO GET THE STATE RESULT 
const useForceUpdate = () => {
  const [, updateState] = React.useState();
  return React.useCallback(() => updateState({}), []);
}



export {
  removePublicFromUrl,
  capitalizeFirstLetter,
  getDateString,
  getTimeString,
  useForceUpdate
}