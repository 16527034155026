
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Layout, ToggleButton, UploadButton } from "../../components";

import { useSelector } from "react-redux";

import { ImageIcon } from '../../assets/Icons';
import { useAddExhibitorMutation, useEditExhibitorMutation, useGetExhibitorsQuery } from "../../store/services/exhibitorService";
import { toast } from "react-toastify";
import { IMAGE_URL } from "../../Api/constants";




const inputStyle = {
    borderWidth: 1, borderColor: '#C5C5C5', borderRadius: 4,
}

const labelStyle = {
    fontFamily: 'IBo',
    fontSize: 14,
    color: '#000000',

}





const ViewExhibitor = () => {



    const { id } = useParams();
    const words = useSelector((state) => state.authReducer.words);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [saveExhibitor, response] = useEditExhibitorMutation();

    const { data: exhibitor } = useGetExhibitorsQuery(id);

    // const { data: organizersList, isLoading: userLoading } = useGetOrganizersQuery();



    const [img, setImg] = useState();
    const [ownerPic, setOwnerPic] = useState();


    const inputs = [
        {
            name: words["Name of campany  or Shop"], state: 'company_name',
        },
        {
            name: words["CR Number"], state: 'cr_number',
        },
        {
            name: words["Email of camany  "], state: 'company_email',
        },
        {
            name: words["Phone Number"], state: 'company_phone',
        },
        {
            name: words["Business Type"], state: 'business_type',
        },
        {
            name: words["Address / City"], state: 'company_city',
        },
        {
            name: words["Number of Products"], state: 'company_no_of_products',
        },
        {
            name: words["Number of Employees"], state: 'company_no_of_employees',
        },

    ];

    const ownerInputs = [
        {
            name: words["Owner Full Name"], state: 'owner_name'
        },
        // {
        //     name: words["ID Number"], state: 'owner_email'
        // },
        {
            name: words["Phone Number"], state: 'owner_phone'
        },
        {
            name: words["Personal Email"], state: 'owner_email'
        },
    ]

    const [formInputs, setFormInputs] = useState({
        company_name: exhibitor?.data?.company_name,
        cr_number: exhibitor?.data?.cr_number,
        company_email: exhibitor?.data?.company_email,
        company_phone: exhibitor?.data?.company_phone,
        business_type: exhibitor?.data?.business_type,
        company_city: exhibitor?.data?.company_city,
        company_no_of_products: exhibitor?.data?.company_no_of_products,
        company_no_of_employees: exhibitor?.data?.company_no_of_employees,

        owner_name: exhibitor?.data?.owner_name,
        owner_email: exhibitor?.data?.owner_email,
        owner_phone: exhibitor?.data?.owner_phone,
        owner_email: exhibitor?.data?.owner_email,


    })




    useEffect(() => {
        const data = exhibitor?.data;
        if (data?.company_name) {
            setFormInputs({
                ...formInputs,
                company_name: exhibitor?.data?.company_name,
                cr_number: exhibitor?.data?.cr_number,
                company_email: exhibitor?.data?.company_email,
                company_phone: exhibitor?.data?.company_phone,
                business_type: exhibitor?.data?.business_type,
                company_city: exhibitor?.data?.company_city,
                company_no_of_products: exhibitor?.data?.company_no_of_products,
                company_no_of_employees: exhibitor?.data?.company_no_of_employees,

                owner_name: exhibitor?.data?.owner_name,
                owner_email: exhibitor?.data?.owner_email,
                owner_phone: exhibitor?.data?.owner_phone,
                owner_email: exhibitor?.data?.owner_email,
            });
        }
    }, exhibitor)




    return (
        <Layout pageTitle={words["View exhibitor"]}>




            <div className="w-full min-h-screen flex pb-10">

                <div className="wrap wrapWidth flex flex-col gap-6">

                    <form
                        className="w-full gap-6">
                        <div style={{ width: "100%", height: 10, backgroundColor: '#7C71FD', borderTopRightRadius: 10, borderTopLeftRadius: 10 }} />

                        <div
                            style={{ marginTop: -10 }}
                            className="w-full flex gap-6 mb-6">

                            <div
                                className="w-full grid-box  pt-4 pb-12 border-solid border-[0px] border-[#D8D8D8] rounded-[10px] flex flex-col">
                                <div style={{ fontFamily: 'IMe', fontSize: 19, color: '#43434C', marginTop: 10, marginLeft: 15 }}>{words["Exhibitor Information"]} </div>
                                <div style={{ width: "100%", height: 1, backgroundColor: '#BEBABA', marginTop: 10, marginBottom: 10 }} />
                                <div className="px-8">
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                        <div style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: '#5A00ED' }} />
                                        <div style={{ fontFamily: 'MSBo', fontSize: 17, color: '#6B6B6B', marginLeft: 10 }}>{words["Company Information"]} </div>
                                    </div>
                                    <div style={{ marginTop: 10 }} />


                                    <div className='flex' >
                                        <div className="flex ">
                                            <div className="field flex flex-col">
                                                <div
                                                    className="img-box flex items-center justify-center h-40 w-64 bg-[#EFEFEF] border border-[#808080] rounded-lg relative "
                                                >


                                                    <img
                                                        alt="img"
                                                        src={IMAGE_URL + exhibitor?.data?.company_logo}
                                                        className="h-full w-full overflow-hidden object-contain"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginLeft: 10 }}>
                                            {
                                                inputs.map((v, i) => {
                                                    return <div
                                                        className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                                        <div
                                                            style={labelStyle}
                                                            className="text-base text-black">{v.name}</div>
                                                        <Input
                                                            disabled={true}
                                                            value={formInputs[v.state]}
                                                            style={inputStyle}
                                                            name={v.name}
                                                        />
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div style={{ width: "100%", height: 1, backgroundColor: '#BEBABA', marginTop: 10, marginBottom: 10 }} />

                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                        <div style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: '#5A00ED' }} />
                                        <div style={{ fontFamily: 'MSBo', fontSize: 17, color: '#6B6B6B', marginLeft: 10 }}>{words["Owner  Information "]} </div>
                                    </div>
                                    <div style={{ marginTop: 10 }} />


                                    <div className='flex' >
                                        <div className="flex ">
                                            <div className="field flex flex-col">
                                                <div
                                                    className="img-box flex items-center justify-center h-40 w-64 bg-[#EFEFEF] border border-[#808080] rounded-lg relative"
                                                >

                                                    <img
                                                        alt="img"
                                                        src={IMAGE_URL + exhibitor?.data?.owner_pic}
                                                        className="h-full w-full overflow-hidden object-contain"
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginLeft: 10 }}>
                                            {
                                                ownerInputs.map((v, i) => {
                                                    return <div
                                                        style={{ marginLeft: 10 }}
                                                        className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                                                        <div
                                                            style={labelStyle}
                                                            className="text-base text-black">{v.name}</div>
                                                        <Input
                                                            style={{ ...inputStyle }}
                                                            value={formInputs[v.state]}
                                                            name={v.name}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>





                    </form>
                </div>
            </div >
        </Layout >
    );



};

export default ViewExhibitor;
