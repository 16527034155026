import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import { Dropdown, Menu, Button } from "antd";
import { getDateString } from "../../utils/functions";
import Modal from "../../components/Modal";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAddExhibitorMutation, useGetExhibitorsQuery } from "../../store/services/exhibitorService";

const Exhibitors = () => {

    const words = useSelector((state) => state.authReducer.words);

    const { data: financeList, isLoading: bookingLoading } = useGetExhibitorsQuery();


    // const [submitData, { data: paymentStatusData, isLoading: paymentStatusLoading }] = useGetExhibitorsQuery();
    // useMarkPaymentAsPaidMutation();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deletedId, setDeletedId] = useState('');
    const navigate = useNavigate();


    const columns = [
        {
            title: words['id'],
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: words['Company name'],
            dataIndex: 'company_name',
            key: 'company_name',
        },
        {
            title: words['CR Number'],
            dataIndex: 'cr_number',
            key: 'cr_number',
        },
        {
            title: words['Company email'],
            dataIndex: 'company_email',
            key: 'company_email',
        },
        {
            title: words['Company phone'],
            dataIndex: 'company_phone',
            key: 'company_phone',
        },
        {
            title: words['Business Type'],
            dataIndex: 'business_type',
            key: 'admin_commission',
        },
        {
            title: words['Products'],
            dataIndex: 'company_no_of_products',
            key: 'company_no_of_products',
        },
        {
            title: words['Employees'],
            dataIndex: 'company_no_of_employees',
            key: 'company_no_of_employees',
        },
        {
            title: words['Owner name'],
            dataIndex: 'owner_name',
            key: 'owner_name',
        },
        {
            title: words['Created at'],
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_, record) => (
                <div>
                    <div
                        className="px-2 py-2 text-left text-sm text-black font-medium"
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {getDateString(record.created_at)}
                    </div>
                </div>
            ),
        },

        {
            title: words["action"],
            key: 'action',
            render: (_, record) => (
                <Dropdown
                    overlay={


                        <Menu
                            onClick={({ key }) => {
                                if (key == 'delete') {
                                    // setDeletedId(record.id);
                                    // setOpenDeleteModal(true);
                                }
                                if (key == 'edit') {
                                    navigate('/dashboard/edit-exhibitor/' + record.id)
                                }
                                if (key == 'view') {
                                    navigate('/dashboard/view-exhibitor/' + record.id)
                                }
                                // doChangePostStatus(key, record)
                            }}
                        >
                            <>
                                <Menu.Item key="view">{words["View"]}</Menu.Item>
                                <Menu.Item key="edit">{words["Edit"]}</Menu.Item>
                                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
                                {/* <Menu.Item key="in-review">In-Review</Menu.Item> */}
                            </>

                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Button>
                        <div
                            className="flex items-center justify-center cursor-pointer text-[#0025F2]"
                        >
                            <DotMenuIcon />
                        </div>
                    </Button>
                </Dropdown>
            ),

        },

    ];



    // useEffect(() => {

    //     if (paymentStatusData?.status == 1) {
    //         toast.success(paymentStatusData.message ?? "Status updated Successfully");
    //     }
    //     if (paymentStatusData?.status == 0) {
    //         // console.log('deleteResponse.isError', deleteResponse.isError)
    //         toast.error("Something wrong try again later!");
    //     }
    // }, [paymentStatusData]);

    return (
        <Layout pageTitle={words["Exhibitors"]} >
            <div className="finance-page min-h-screen flex">
                {bookingLoading ? (
                    <div className="wrap wrapWidth flex flex-col items-center ">
                        <Loader />
                    </div>
                ) : (
                    <div className="wrap wrapWidth flex flex-col">
                        <div className="flex flex-col gap-5 mt-6">
                            <div className="flex items-center justify-between gap-3">

                                <div className="flex flex-1 justify-end">
                                    <Link
                                        to="/dashboard/add-exhibitor"
                                        className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1"
                                    >
                                        <div className="flex items-center justify-center text-white font-normal text-2xl">
                                            +
                                        </div>
                                        <label className="text-white cursor-pointer">
                                            {words['Add exhibitor']}
                                        </label>
                                    </Link>
                                </div>
                            </div>

                            <div style={{ marginTop: -20 }}>
                                <DataTable
                                    searchContainerStyle={{ width: "40%", }}
                                    data={financeList?.data} columns={columns} />
                            </div>

                        </div>
                    </div>
                )}
            </div>




        </Layout>
    );
};

export default Exhibitors;
